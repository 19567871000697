<template>
	<div>
		<div class="columns">
			<div class="rows">
				<BattleLogRow
					:pet="turn.player[0]"
					:prevPet="prevTurn ? prevTurn.player[0] : null"
				/>
				<BattleLogRow
					:pet="turn.player[1]"
					:prevPet="prevTurn ? prevTurn.player[1] : null"
				/>
				<BattleLogRow
					:pet="turn.player[2]"
					:prevPet="prevTurn ? prevTurn.player[2] : null"
				/>
			</div>
			<div class="rows">
				<BattleLogRow
					right
					:pet="turn.enemy[0]"
					:prevPet="prevTurn ? prevTurn.enemy[0] : null"
				/>
				<BattleLogRow
					right
					:pet="turn.enemy[1]"
					:prevPet="prevTurn ? prevTurn.enemy[1] : null"
				/>
				<BattleLogRow
					right
					:pet="turn.enemy[2]"
					:prevPet="prevTurn ? prevTurn.enemy[2] : null"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import BattleLogRow from '@/views/battle-logs/components/BattleLogRow';

export default {
	name: 'BattleLogDisplay',
	components: { BattleLogRow },
	props: {
		turn: {
			type: Object,
		},
		prevTurn: {
			type: Object,
		},
	},
	data: () => ({}),
	computed: {},
	methods: {},
};
</script>

<style scoped>
.rows {
	display: flex;
	justify-content: center;
	flex-direction: column;
}

.columns {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
}
</style>
