<template>
	<div class="table">
		<v-card-title>
			<v-avatar size="32" class="avatar">
				<v-img :src="avatarUrl" alt="avatar" />
			</v-avatar>
			{{ title }}
		</v-card-title>
		<v-tabs>
			<v-tab @click="global = false">Saved</v-tab>
			<v-tab @click="global = 'recent'">Global</v-tab>
			<v-tab @click="global = 'popular'">Popular</v-tab>
		</v-tabs>
		<v-data-table
			v-if="isLoggedIn"
			:headers="headers"
			:items="logs"
			:loading="fetching"
			loading-text="Fetching logs..."
			:footer-props="footerProps"
			:server-items-length="totalLogs"
			:options.sync="options"
		>
			<template v-slot:item.title="{ item }">
				<div v-html="item.title"></div>
			</template>
			<template v-slot:item.action="{ item }">
				<v-btn color="primary" text @click="view(item.uuid)">
					<v-icon>mdi-eye-outline</v-icon>
				</v-btn>
				<v-btn
					v-if="!global"
					color="error"
					text
					@click="deleteLog(item.uuid, item.title)"
				>
					<v-icon>mdi-trash-can-outline</v-icon>
				</v-btn>
			</template>
		</v-data-table>
		<div class="login" v-else>
			<v-card-title>Please log in to view your saved battles</v-card-title>
			<v-btn color="primary" @click="login">Log in</v-btn>
		</div>
	</div>
</template>

<script>
import endpoints from '@/router/routes.js';

export default {
	name: 'BattleLogSavedTable',
	data: () => ({
		redirect: process.env.VUE_APP_BACKEND + '/api/auth/discord',
		options: {
			page: 1,
			itemsPerPage: 25,
		},
		fetching: true,
		fetchedLogs: [],
		totalLogs: 0,
		global: false,
		footerProps: {
			'items-per-page-options': [25, 50, 100],
		},
	}),
	watch: {
		options: {
			handler() {
				this.getLogs();
			},
			deep: true,
		},
		async global() {
			this.options.page = 1;
			this.options.itemsPerPage = 25;
			await this.getTotalLogCount();
			this.getLogs();
		},
	},
	computed: {
		headers() {
			if (this.global) {
				return [
					{ text: 'Battles', value: 'title', sortable: false },
					{ text: 'Streak', value: 'streak', sortable: false },
					{ text: 'Saves', value: 'saveCount', sortable: false },
					{ text: 'Date', value: 'date', sortable: false },
					{ text: '', value: 'action', sortable: false },
				];
			} else {
				return [
					{ text: 'Battles', value: 'title', sortable: false },
					{ text: 'Streak', value: 'streak', sortable: false },
					{ text: 'Date', value: 'date', sortable: false },
					{ text: '', value: 'action', sortable: false },
				];
			}
		},
		title() {
			return `${this.user.fullName}'s Saved Battle Logs`;
		},
		avatarUrl() {
			if (this.user.avatar) {
				return `https://cdn.discordapp.com/avatars/${this.user.id}/${this.user.avatar}.png`;
			} else {
				return `https://cdn.discordapp.com/embed/avatars/${
					parseInt(this.user.discriminator) % 5
				}.png`;
			}
		},
		user() {
			return this.$store.getters.user;
		},
		logs() {
			return this.fetchedLogs.map((log) => {
				let title = log.uuid;
				if (log.player && log.enemy) {
					title = log.player.name || 'Player';
					title += ' vs ';
					title += log.enemy.name || 'Enemy';
				}
				let date = new Date(log.createdAt);
				date = date.toLocaleString('en-us', {
					year: 'numeric',
					month: '2-digit',
					day: '2-digit',
				});
				return {
					title: this.convertString(title),
					date: date,
					streak: log.player?.streak || '?',
					...log,
				};
			});
		},
		isLoggedIn() {
			return !!this.$store.getters.user?.id;
		},
	},
	methods: {
		login() {
			this.$store.dispatch('setPreLoginUrl', this.$route.fullPath);
			window.location.href = this.redirect;
		},
		view(uuid) {
			this.$router.push(`${endpoints.BATTLE_LOG}?uuid=${uuid}`);
		},
		async deleteLog(uuid, title) {
			const opt = {
				text: `<b>Are you sure you want to delete this log?</b><br/><br/>You are about to delete:<br/>${title}`,
				buttons: [
					{
						text: 'Cancel',
						color: 'warning',
						returns: false,
					},
					{
						text: 'Delete',
						color: 'error',
						returns: true,
					},
				],
			};
			const result = await this.$modal(opt).showWarn();
			if (!result) return;
			try {
				await this.$store.dispatch('deleteSavedBattle', uuid);
				const opt = {
					text: 'Successfuly deleted battle log!',
					imgUrl: 'owo.png',
				};
				this.$modal(opt).showInfo();
				this.getLogs();
			} catch (err) {
				console.error(err);
				const opt = {
					text: 'Failed to delete battle log.',
					imgUrl: 'owo-cry.png',
				};
				this.$modal(opt).showError();
			}
		},
		async getLogs() {
			this.fetching = true;
			try {
				this.fetchedLogs = await this.$store.dispatch('getSavedBattleLog', {
					limit: this.options.itemsPerPage,
					page: this.options.page,
					global: this.global,
				});
			} catch (err) {
				const opt = {
					text: 'Failed to get saved battle logs',
					imgUrl: 'owo-cry.png',
				};
				return this.$modal(opt).showError();
			} finally {
				this.fetching = false;
			}
		},
		async getTotalLogCount() {
			try {
				if (this.global) {
					this.totalLogs = await this.$store.dispatch('getTotalLogs');
				} else {
					this.totalLogs = await this.$store.dispatch('getTotalSavedLogs');
				}
			} catch (err) {
				const opt = {
					text: 'Failed to get saved battle logs',
					imgUrl: 'owo-cry.png',
				};
				return this.$modal(opt).showError();
			}
		},
		convertString(text) {
			return text
				.replaceAll('\n', '<br />')
				.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>')
				.replace(/__(.*?)__/g, '<u>$1</u>')
				.replace(/~~(.*?)~~/g, '<i>$1</i>')
				.replace(/--(.*?)--/g, '<del>$1</del>')
				.replace(/<<(.*?)>>/g, "<a href='$1'>Link</a>")
				.replace(/<a?:[\w_]+:\d+>/gi, (match) => {
					const url = this.getEmojiUrl(match);
					return `<img src="${url}" class="small-emoji" />`;
				});
		},
	},
	async mounted() {
		await this.$store.dispatch('getUser');
		if (this.isLoggedIn) {
			await this.getTotalLogCount();
			await this.getLogs();
		}
	},
};
</script>

<style scoped>
.login {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
}

.table {
	width: 100%;
	height: 100%;
}

.avatar {
	margin-right: 10px;
}

::v-deep .small-emoji {
	width: 18px;
	height: 18px;
	margin-right: 0px;
	margin-bottom: -3px;
}
</style>

<style>
.v-data-table__wrapper > table > tbody > tr:hover {
	background: inherit !important;
}
</style>
