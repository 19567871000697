<template>
	<div v-if="pet" class="outer-border" :class="{ 'right-border': right }">
		<v-img
			:src="petUrl"
			class="pet-image"
			v-ripple="{ class: `primary--text` }"
			@click="showAnimal"
		></v-img>
		<div class="pet-info">
			<BattleLogBar
				:percentages="pet.hp"
				:prevPercentages="prevPet ? prevPet.hp : null"
				:right="right"
				hp
			/>
			<BattleLogBar
				:percentages="pet.wp"
				:prevPercentages="prevPet ? prevPet.wp : null"
				:right="right"
				wp
			/>
			<div class="pet-text" :class="{ 'reverse-order': right }">
				<div>LvL {{ pet.info.stats.lvl }}</div>
				<div v-html="convertString(pet.info.nickname)"></div>
			</div>
			<div class="weapon-row" :class="{ 'reverse-order': right }">
				<div class="weapon-image">
					<img
						v-if="weaponUrl"
						:src="weaponUrl"
						width="100%"
						height="100%"
						v-ripple="{ class: `primary--text` }"
						@click="showWeapon"
					/>
				</div>
				<div class="stat-col">
					<div class="stat-row">
						<div class="stat-col">
							<img :src="att" class="stat-icon" />
							<div class="stat-text">{{ attText }}</div>
						</div>
						<div class="stat-col">
							<img :src="pr" class="stat-icon" />
							<div class="stat-text">{{ prText }}</div>
						</div>
					</div>
					<div class="stat-row">
						<div class="stat-col">
							<img :src="mag" class="stat-icon" />
							<div class="stat-text">{{ magText }}</div>
						</div>
						<div class="stat-col">
							<img :src="mr" class="stat-icon" />
							<div class="stat-text">{{ mrText }}</div>
						</div>
					</div>
				</div>
				<div class="stat-col">
					<div v-for="buff in pet.buffs" :key="buff.emoji">
						<img :src="getEmojiUrl(buff.emoji)" class="stat-icon" />
					</div>
				</div>
			</div>
		</div>
		<BattleLogAnimalInfo :animal="pet.info.animal" ref="animalModal" />
		<BattleLogWeaponInfo :weapon="pet.info.weapon" ref="weaponModal" />
	</div>
</template>

<script>
import BattleLogBar from '@/views/battle-logs/components/BattleLogBar';
import BattleLogAnimalInfo from '@/views/battle-logs/components/BattleLogAnimalInfo';
import BattleLogWeaponInfo from '@/views/battle-logs/components/BattleLogWeaponInfo';

export default {
	name: 'BattleLogRow',
	components: { BattleLogBar, BattleLogAnimalInfo, BattleLogWeaponInfo },
	props: {
		right: {
			type: Boolean,
			default: false,
		},
		pet: {
			type: Object,
		},
		prevPet: {
			type: Object,
		},
	},
	data: () => ({
		att: null,
		mag: null,
		mr: null,
		pr: null,
	}),
	computed: {
		attText() {
			return this.pet?.info.stats.att.reduce((a, b) => a + b);
		},
		magText() {
			return this.pet?.info.stats.mag.reduce((a, b) => a + b);
		},
		prText() {
			return this.pet?.info.stats.pr.reduce((a, b) => a + b);
		},
		mrText() {
			return this.pet?.info.stats.mr.reduce((a, b) => a + b);
		},
		petUrl() {
			return this.getEmojiUrl(this.pet.info.animal.value);
		},
		weaponUrl() {
			return this.getEmojiUrl(this.pet.info.weapon?.emoji);
		},
	},
	methods: {
		convertString(text) {
			return text
				.replaceAll('\n', '<br />')
				.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>')
				.replace(/__(.*?)__/g, '<u>$1</u>')
				.replace(/~~(.*?)~~/g, '<i>$1</i>')
				.replace(/--(.*?)--/g, '<del>$1</del>')
				.replace(/<<(.*?)>>/g, "<a href='$1'>Link</a>")
				.replace(/<a?:[\w_]+:\d+>/gi, (match) => {
					const url = this.getEmojiUrl(match);
					return `<img src="${url}" class="small-emoji-name" />`;
				});
		},
		showAnimal() {
			this.$refs.animalModal.showModal();
		},
		showWeapon() {
			this.$refs.weaponModal.showModal();
		},
	},
	mounted() {
		this.att = this.getEmojiUrl('<:att:531616155450998794>');
		this.mag = this.getEmojiUrl('<:mag:531616156231139338>');
		this.mr = this.getEmojiUrl('<:mr:531616156226945024>');
		this.pr = this.getEmojiUrl('<:pr:531616156222488606>');
	},
};
</script>

<style scoped>
.outer-border {
	display: flex;
	padding: 10px;
}

.right-border {
	flex-direction: row-reverse;
}

.pet-image {
	width: 12.5vw;
	max-width: 100px;
	height: 12.5vw;
	max-height: 100px;
	border: 1px solid white;
}

.pet-info {
	display: flex;
	flex-direction: column;
}

.bar {
	width: 30vw;
	max-width: 240px;
	height: 2.5vw;
	max-height: 20px;
	border: 1px solid white;
}

.pet-text {
	display: flex;
	justify-content: space-between;
	margin-left: 5px;
}

@media all and (max-width: 800px) {
	.pet-text {
		font-size: 2.1vw;
	}
}

.reverse-order {
	flex-direction: row-reverse;
	margin-right: 5px;
	margin-left: 0px;
}

.weapon-row {
	display: flex;
}

.weapon-image {
	width: 4.625vw;
	max-width: 37px;
	height: 4.625vw;
	max-height: 37px;
	margin-left: 5px;
}

.stat-col {
	display: flex;
}

.stat-row {
	display: flex;
	flex-direction: column;
}

.stat-icon {
	width: 2.2vw;
	max-width: 18.5px;
	height: 2.2vw;
	max-height: 18.5px;
	margin-left: 5px;
}

.stat-text {
	margin-top: -3px;
}

@media all and (max-width: 800px) {
	.stat-text {
		font-size: 2.1vw;
	}
}

::v-deep .small-emoji-name {
	width: 18px;
	height: 18px;
	margin-right: 0px;
	margin-bottom: -4px;
}
</style>
