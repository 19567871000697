<template>
	<div>
		<v-dialog v-model="show" width="min-content" height="min-content">
			<v-card class="info-card" width="400">
				<v-list-item three-line>
					<v-list-item-content>
						<v-list-item-title class="text-h5 mb-1">
							{{ animal.name }}
						</v-list-item-title>
						<v-list-item-subtitle class="wrap-text">
							{{ animal.desc }}
						</v-list-item-subtitle>
						<v-list-item-content>
							<div><b>Rarity: </b> {{ animal.rank }}</div>
							<div><b>Points: </b> {{ animal.points }}</div>
							<div><b>Price: </b> {{ animal.price }}</div>
							<div><b>Essence: </b> {{ animal.essence }}</div>
							<v-container class="stats">
								<v-row no-gutters class="nowrap">
									<v-col class="stat-row">
										<img :src="hp" class="stat-icon" />
										<div class="stat-text">{{ animal.hpr }}</div>
									</v-col>
									<v-col class="stat-row">
										<img :src="att" class="stat-icon" />
										<div class="stat-text">{{ animal.attr }}</div>
									</v-col>
									<v-col class="stat-row">
										<img :src="pr" class="stat-icon" />
										<div class="stat-text">{{ animal.prr }}</div>
									</v-col>
								</v-row>
								<v-row no-gutters class="nowrap">
									<v-col class="stat-row">
										<img :src="wp" class="stat-icon" />
										<div class="stat-text">{{ animal.wpr }}</div>
									</v-col>
									<v-col class="stat-row">
										<img :src="mag" class="stat-icon" />
										<div class="stat-text">{{ animal.magr }}</div>
									</v-col>
									<v-col class="stat-row">
										<img :src="mr" class="stat-icon" />
										<div class="stat-text">{{ animal.mrr }}</div>
									</v-col>
								</v-row>
							</v-container>
						</v-list-item-content>
					</v-list-item-content>

					<v-list-item-avatar tile size="80">
						<img :src="getEmojiUrl(animal.value)" class="info-image" />
					</v-list-item-avatar>
				</v-list-item>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
export default {
	name: 'BattleLogAnimalInfo',
	props: {
		animal: {
			type: Object,
		},
	},
	computed: {},
	data: () => ({
		show: false,
		att: null,
		mag: null,
		mr: null,
		pr: null,
		hp: null,
		wp: null,
	}),
	methods: {
		showModal() {
			this.show = true;
		},
	},
	mounted() {
		this.att = this.getEmojiUrl('<:att:531616155450998794>');
		this.mag = this.getEmojiUrl('<:mag:531616156231139338>');
		this.mr = this.getEmojiUrl('<:mr:531616156226945024>');
		this.pr = this.getEmojiUrl('<:pr:531616156222488606>');
		this.hp = this.getEmojiUrl('<:hp:531620120410456064>');
		this.wp = this.getEmojiUrl('<:wp:531620120976687114>');
	},
};
</script>

<style scoped>
.info-card {
	border: 3px solid var(--v-primary-base);
}

.info-image {
	margin-right: 10px;
}

.title {
	display: flex;
	flex-direction: row;
}

.wrap-text {
	-webkit-line-clamp: unset !important;
	font-style: italic;
}

.stat-row {
	display: flex;
	width: min-content;
}

.stat-column {
	display: flex;
	flex-direction: column;
}

.stat-icon {
	width: 18.5px;
	height: 18.5px;
	margin-right: 5px;
}

.stat-text {
	margin-right: 10px;
}

.stats {
	margin-top: 20px;
	padding: 0px;
	max-width: 150px;
	margin-left: 0px;
}

.nowrap {
	flex-wrap: nowrap;
}
</style>
