<template>
	<div class="log scroll">
		<ParticleBackground fullscreen />
		<BattleLogCard class="card" :logs="logs" :loading="fetching" :uuid="uuid" />
	</div>
</template>

<script>
import ParticleBackground from '@/components/particles/ParticleBackground';
import BattleLogCard from '@/views/battle-logs/components/BattleLogCard';

export default {
	name: 'BattleLogPage',
	components: {
		ParticleBackground,
		BattleLogCard,
	},
	data: () => ({
		logs: {},
		fetching: false,
		uuid: null,
	}),
	watch: {
		'$route.params': {
			handler() {
				this.parseParams();
			},
			deep: true,
			immediate: true,
		},
	},
	computed: {
		isLoggedIn() {
			return !!this.$store.getters.user?.id;
		},
	},
	methods: {
		addInitialTurn() {
			const firstLog = JSON.parse(JSON.stringify(this.logs.logs[0]));
			firstLog.player.forEach((player) => {
				player.hp[0] = player.hp[1] + player.hp[3];
				player.wp[0] = player.wp[1] + player.wp[3];
			});
			firstLog.enemy.forEach((enemy) => {
				enemy.hp[0] = enemy.hp[1] + enemy.hp[3];
				enemy.wp[0] = enemy.wp[1] + enemy.wp[3];
			});
			this.logs.logs.splice(0, 0, {
				battleLogs: [],
				enemy: firstLog.enemy,
				player: firstLog.player,
			});
		},
		async parseParams() {
			this.fetching = true;
			this.logs = {};
			this.uuid = null;
			const { uuid } = this.$route.query;
			if (!uuid) {
				this.fetching = false;
				return;
			}
			try {
				this.logs = await this.$store.dispatch('getBattleLog', uuid);
			} catch (err) {
				const opt = {
					text: 'Failed to get battle log',
					imgUrl: 'owo-cry.png',
				};
				return this.$modal(opt).showError();
			}
			this.addInitialTurn();
			this.uuid = uuid;
			this.fetching = false;
		},
	},
	async mounted() {
		// await this.parseParams();
	},
};
</script>

<style scoped>
.log {
	position: relative;
	min-height: 100vh;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
}

.scroll::-webkit-scrollbar-track {
	background: var(--v-background_dark2-base);
	border-left: 1px solid var(--v-background-base);
}

.scroll::-webkit-scrollbar-thumb {
	background: var(--v-background-base);
	border: solid 3px var(--v-background_dark2-base);
	border-radius: 7px;
}

.scroll::-webkit-scrollbar-thumb:hover {
	background: var(--v-secondary-base);
}

.card {
	margin: 100px 0px;
}
</style>
