<template>
	<span class="loader" :style="cssVars"></span>
</template>

<script>
export default {
	name: 'LoadingSpinner',
	props: {
		size: {
			type: Number,
			default: 48,
			required: false,
		},
	},
	computed: {
		cssVars() {
			return {
				'--spinner-size': this.size + 'px',
			};
		},
	},
};
</script>

<style scoped>
.loader {
	width: var(--spinner-size);
	height: var(--spinner-size);
	display: inline-block;
	position: relative;
}

.loader::after,
.loader::before {
	content: '';
	box-sizing: border-box;
	width: var(--spinner-size);
	height: var(--spinner-size);
	border: 2px solid #fff;
	position: absolute;
	left: 0;
	top: 0;
	animation: rotation 2s ease-in-out infinite alternate;
}

.loader::after {
	border-color: var(--v-secondary-base);
	animation-direction: alternate-reverse;
}

@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}
</style>
