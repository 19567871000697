<template>
	<div v-if="weapon">
		<v-dialog v-model="show" width="min-content" height="min-content">
			<v-card class="info-card" width="400">
				<v-list-item three-line>
					<v-list-item-content>
						<v-list-item-title class="text-h5 mb-1">
							{{ weapon.name }}
						</v-list-item-title>
						<v-list-item-content>
							<div>
								<b>ID: </b><span class="uwid"> {{ weapon.uwid }}</span>
							</div>
							<div>
								<b>Rank: </b>
								<img
									:src="getEmojiUrl(weapon.rank.emoji)"
									class="small-emoji"
								/>{{ weapon.rank.name }}
							</div>
							<div><b>Quality: </b> {{ weapon.avgQuality }}%</div>
							<br />
							<div><b>Mana Cost: </b> {{ weapon.manaCost }}</div>
							<div class="breaker"></div>
							<div><b>Description: </b> <span v-html="description"></span></div>
							<span v-if="weapon.buffs && weapon.buffs.length">
								<div class="breaker"></div>
								<div
									v-for="buff in weapon.buffs"
									:key="buff.id + buff.avgQuality"
								>
									<img :src="getEmojiUrl(buff.emoji)" class="small-emoji" />
									<b>{{ buff.name }} - </b>
									<span v-html="getDesc(buff.desc)"></span>
								</div>
							</span>
							<span v-if="weapon.passives && weapon.passives.length">
								<div class="breaker"></div>
								<div
									v-for="passive in weapon.passives"
									:key="passive.id + passive.avgQuality"
								>
									<img :src="getEmojiUrl(passive.emoji)" class="small-emoji" />
									<b>{{ passive.name }} - </b>
									<span v-html="getDesc(passive.desc)"></span>
								</div>
							</span>
						</v-list-item-content>
					</v-list-item-content>

					<v-list-item-avatar tile size="80">
						<img :src="getEmojiUrl(weapon.emoji)" class="info-image" />
					</v-list-item-avatar>
				</v-list-item>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
export default {
	name: 'BattleLogWeaponInfo',
	props: {
		weapon: {
			type: Object,
		},
	},
	computed: {
		description() {
			return this.getDesc(this.weapon.desc);
		},
	},
	data: () => ({
		show: false,
	}),
	methods: {
		showModal() {
			console.log(this.weapon);
			this.show = true;
		},
		getDesc(desc) {
			let description = desc
				.replaceAll('\n', '<br />')
				.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>')
				.replace(/__(.*?)__/g, '<u>$1</u>')
				.replace(/~~(.*?)~~/g, '<i>$1</i>')
				.replace(/--(.*?)--/g, '<del>$1</del>')
				.replace(/<<(.*?)>>/g, "<a href='$1'>Link</a>");

			description = description.replace(/<a?:[\w_]+:\d+>/gi, (match) => {
				const url = this.getEmojiUrl(match);
				return `<img src="${url}" class="small-emoji" />`;
			});

			return description;
		},
	},
	mounted() {},
};
</script>

<style scoped>
.info-card {
	border: 3px solid var(--v-primary-base);
}

.info-image {
	margin-right: 10px;
}

.title {
	display: flex;
	flex-direction: row;
}

.wrap-text {
	-webkit-line-clamp: unset !important;
	font-style: italic;
}

.stat-row {
	display: flex;
	width: min-content;
}

.stat-column {
	display: flex;
	flex-direction: column;
}

.stat-icon {
	width: 18.5px;
	height: 18.5px;
	margin-right: 5px;
}

.stat-text {
	margin-right: 10px;
}

.stats {
	margin-top: 20px;
	padding: 0px;
	max-width: 150px;
	margin-left: 0px;
}

.nowrap {
	flex-wrap: nowrap;
}

.uwid {
	font-family: 'Lucida Console', 'Courier New', monospace;
}

::v-deep .small-emoji {
	width: 20px;
	height: 20px;
	margin-right: 4px;
	margin-bottom: -5px;
}

.breaker {
	height: 15px;
}
</style>
