<template>
	<v-progress-linear
		v-model="percent"
		class="bar"
		:buffer-value="previousPercent"
		:color="hp ? '#ae443f' : '#5b75a4'"
	>
		<template v-slot:default="{}">
			<v-progress-linear
				v-model="overPercent"
				class="over-bar"
				:buffer-value="overPreviousPercent"
				:color="hp ? '#5cbb62' : '#4cb2cf'"
			>
			</v-progress-linear>
			<div class="bar-text">{{ barText }}</div>
		</template>
	</v-progress-linear>
</template>

<script>
export default {
	name: 'BattleLogBar',
	props: {
		percentages: {
			type: Array,
		},
		prevPercentages: {
			type: Array,
		},
		hp: {
			type: Boolean,
			default: false,
		},
		wp: {
			type: Boolean,
			default: false,
		},
		right: {
			type: Boolean,
			default: false,
		},
	},
	data: () => ({}),
	computed: {
		percent() {
			const percent =
				this.percentages[0] / (this.percentages[1] + this.percentages[3]);
			return Math.floor(percent * 100);
		},
		overPercent() {
			return Math.max(this.percent - 100, 0);
		},
		barText() {
			return `${Math.ceil(this.percentages[0])} / ${Math.ceil(
				this.percentages[1] + this.percentages[3]
			)}`;
		},
		previousPercent() {
			if (!this.prevPercentages) return 0;
			const percent =
				this.prevPercentages[0] /
				(this.prevPercentages[1] + this.prevPercentages[3]);
			return Math.floor(percent * 100);
		},
		overPreviousPercent() {
			return Math.max(this.previousPercent - 100, 0);
		},
	},
	methods: {},
	mounted() {},
};
</script>

<style scoped>
.bar {
	width: 30vw;
	max-width: 240px;
	height: 2.5vw !important;
	max-height: 20px !important;
	border: 1px solid white;
	pointer-events: none;
}

.over-bar {
	width: 30vw;
	max-width: 240px;
	height: 2.5vw !important;
	max-height: 20px !important;
	border: 0px;
	pointer-events: none;
	position: absolute;
	z-index: 10;
}

.bar-text {
	font-size: 1rem;
	z-index: 11;
}

@media all and (max-width: 800px) {
	.bar-text {
		font-size: 2.1vw;
		z-index: 11;
	}
}
</style>
