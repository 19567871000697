<template>
	<div>
		<v-card class="log-card" :style="cardBorder">
			<span v-if="loading" class="loading">
				<v-card-title> Fetching Battle Log... </v-card-title>
				<LoadingSpinner :size="30" />
			</span>
			<span v-else-if="uuid">
				<v-card-title class="card-text" v-html="title"></v-card-title>
				<v-card-subtitle class="card-text"
					>Player's Streak: {{ logs.battle.player.streak }}</v-card-subtitle
				>
				<div class="display">
					<BattleLogDisplay :turn="currentTurn" :prevTurn="previousTurn" />
					<div class="pages">
						<v-btn :disabled="page <= 0" @click="prev" icon size="30">
							<v-icon>mdi-chevron-left</v-icon>
						</v-btn>
						<div>{{ page + 1 }} / {{ maxPages }}</div>
						<v-btn :disabled="page > maxPages - 2" @click="next" icon>
							<v-icon>mdi-chevron-right</v-icon>
						</v-btn>
					</div>
				</div>
				<div class="log-box">
					<div v-html="currentLog"></div>
				</div>
				<v-card-actions>
					<v-btn color="primary" class="save-button" text @click="viewSaved">
						<v-icon class="button-icon">mdi-format-list-bulleted</v-icon> View
						Saved
					</v-btn>
					<v-spacer></v-spacer>
					<v-btn
						:disabled="disableSave || logs.saved"
						color="yellow"
						class="save-button"
						text
						@click="save"
					>
						<v-icon class="button-icon">mdi-content-save</v-icon> {{ saveText }}
					</v-btn>
				</v-card-actions>
			</span>
			<span v-show="!loading && !uuid">
				<BattleLogSavedTable />
			</span>
		</v-card>
	</div>
</template>

<script>
import LoadingSpinner from '@/components/LoadingSpinner';
import BattleLogDisplay from '@/views/battle-logs/components/BattleLogDisplay';
import BattleLogSavedTable from '@/views/battle-logs/components/BattleLogSavedTable';
import endpoints from '@/router/routes.js';

export default {
	name: 'BattleLogCard',
	components: { LoadingSpinner, BattleLogDisplay, BattleLogSavedTable },
	props: {
		logs: {
			type: Object,
		},
		loading: {
			type: Boolean,
		},
		uuid: {
			type: String,
		},
	},
	data: () => ({
		page: 0,
		disableSave: false,
		redirect: process.env.VUE_APP_BACKEND + '/api/auth/discord',
	}),
	computed: {
		maxPages() {
			return this.logs?.logs.length;
		},
		cardBorder() {
			return 'border: 3px solid var(--v-primary-base);';
		},
		title() {
			if (this.logs.battle) {
				let playerName = this.logs.battle.player?.name || 'Player Team';
				playerName = this.convertString(playerName, 'title');
				let enemyName = this.logs.battle.enemy?.name || 'Enemy Team';
				enemyName = this.convertString(enemyName, 'title');
				return `<b>${playerName}</b>&nbsp;&nbsp;vs&nbsp;&nbsp;<b>${enemyName}</b>`;
			}
			return '';
		},
		currentTurn() {
			return this.logs?.logs[this.page];
		},
		currentLog() {
			let logs = '';
			this.currentTurn.battleLogs.forEach((log) => {
				let spaces = '';
				log = log.replace(/^\s+/i, (match) => {
					spaces = match;
					return '';
				});
				log = log.replace(/^\[\w+\]/i, (match) => {
					return `<strong class="log-tag">${match}</strong>`;
				});
				spaces = spaces.replace(/\s/gi, '&nbsp;');
				logs += spaces + log + '<br>';
			});
			if (logs === '') {
				logs = 'No logs this turn';
			}
			logs = this.convertString(logs, 'log');
			return logs;
		},
		previousTurn() {
			if (this.page > 0) {
				return this.logs?.logs[this.page - 1];
			} else {
				return null;
			}
		},
		saveText() {
			if (this.disableSave || this.logs.saved) {
				return 'Saved!';
			} else if (!this.$store.getters.user?.id) {
				return 'Log in';
			} else {
				return 'Save';
			}
		},
	},
	methods: {
		convertString(text, name) {
			return text
				.replaceAll('\n', '<br />')
				.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>')
				.replace(/__(.*?)__/g, '<u>$1</u>')
				.replace(/~~(.*?)~~/g, '<i>$1</i>')
				.replace(/--(.*?)--/g, '<del>$1</del>')
				.replace(/<<(.*?)>>/g, "<a href='$1'>Link</a>")
				.replace(/<a?:[\w_]+:\d+>/gi, (match) => {
					const url = this.getEmojiUrl(match);
					return `<img src="${url}" class="small-emoji-${name}" />`;
				});
		},
		viewSaved() {
			this.page = 0;
			this.disableSave = false;
			this.$router.push(endpoints.BATTLE_LOG);
		},
		prev() {
			this.page--;
		},
		next() {
			this.page++;
		},
		async save() {
			if (!this.$store.getters.user?.id) {
				this.$store.dispatch('setPreLoginUrl', this.$route.fullPath);
				window.location.href = this.redirect;
				return;
			}
			try {
				await this.$store.dispatch('saveBattleLog', this.uuid);
			} catch (err) {
				const opt = {
					text: 'Failed to save battle log...',
					imgUrl: 'owo-cry.png',
				};
				return this.$modal(opt).showError();
			}
			this.disableSave = true;
		},
	},
};
</script>

<style scoped>
.log-card {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	transition: all 0.5s ease;
	min-width: 400px;
	min-height: 300px;
}

.avatar-border {
	display: flex;
	align-items: center;
	border-radius: 50%;
	width: 69px;
	height: 69px;
	background: var(--v-background-base);
	transition: all 0.5s ease;
}

.card-text {
	width: 100%;
	text-align: center;
	align-items: center;
	justify-content: center;
}

.welcome-text {
	display: inline-block;
	word-break: break-word;
	text-align: center;
	width: 100%;
}

.center-text {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
}

.uuid {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	font-size: 0.7rem !important;
	margin-right: -28px;
	margin-bottom: -10px;
	margin-top: -10px;
	color: rgba(255, 255, 255, 0.3);
}

.loading {
	min-height: 300px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.pages {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.log-box {
	margin: 20px;
	padding: 20px;
	background: var(--v-background-darken1);
	border: 1px solid var(--v-secondary-base);
	border-radius: 8px;
	font-family: 'Lucida Console', 'Courier New', monospace;
}

::v-deep .log-tag {
	color: var(--v-primary-base);
}

.log-box {
	font-size: 1rem;
}

@media all and (max-width: 800px) {
	.log-box {
		font-size: 2vw;
	}
}

.save-button {
	margin-right: 10px;
	margin-left: 10px;
	margin-bottom: 10px;
}

.button-icon {
	margin-right: 5px;
}

::v-deep .small-emoji-title {
	width: 24px;
	height: 24px;
	margin-right: 0px;
	margin-bottom: -4px;
}

::v-deep .small-emoji-log {
	width: 20px;
	height: 20px;
	margin-right: 0px;
	margin-bottom: -4px;
}
</style>
